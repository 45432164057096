<template>
  <v-app>
    <v-form v-if="name" ref="form" class="login-form">
      <div class="text-center">
        <img v-if="!logo" :src="require('@/assets/mkgest-icon.png')" class="mb-6" style="width: 120px" />
        <img v-if="logo" :src="logo" class="mb-6" style="width: 120px" />
      </div>
      <div class="display-2 font-weight-light text-center login-title">
        {{ name }}
      </div>
      <v-col cols="12">
        <v-text-field v-model="email" label="Email" required />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="password"
          label="Password"
          required
          :append-icon="showPasssword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasssword ? 'text' : 'password'"
          @click:append="showPasssword = !showPasssword"
        />
      </v-col>
      <v-col v-if="failed" cols="12" class="error--text text-center">
        {{ failed }}
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn color="secondary" class="mr-0" @click="submit()">Login</v-btn>
      </v-col>
    </v-form>
  </v-app>
</template>

<script>
import Api from '@/services/Api'
import ChatApi from '@/services/ChatApi'
import Utils from '@/services/Utils'
import axios from 'axios'
import mqtt from 'mqtt'

export default {
  data() {
    const userLogin = localStorage.getItem('user_login')
    let email = ''
    let password = ''

    try {
      if (userLogin) {
        email = JSON.parse(userLogin).email
        password = JSON.parse(userLogin).password
      }
    } catch {}

    return {
      email: email,
      password: password,
      showPasssword: false,
      failed: false,
      jsonAdmin: null,
      logo: null,
      name: null,
    }
  },
  beforeMount: function () {
    this.main()
  },
  methods: {
    main: function () {
      const user = Utils.getUser()
      if (user) {
        this.email = user.email
        this.password = user.password
      }
      this.handleAdminValidation()
      Utils.removeRequestCache()
      if (window.urlToOpenAfterLogin) {
        this.submit()
      }
    },
    handleAdminValidation: function () {
      this.jsonAdmin = window.jsonAdmin
      if (!window.jsonAdmin || window.jsonAdminDefault) {
        this.logo = null
        this.name = 'Personal Trainer Dashboard'
      } else {
        this.logo =
          'https://ptclient.mkgest.com/dashboard/' +
          Math.abs(window.jsonAdmin.pt_id) +
          '/' +
          (window.jsonAdmin.logo ? window.jsonAdmin.logo : 'logo.jpg')
        this.name = window.jsonAdmin.name ? window.jsonAdmin.name : 'Personal Trainer Dashboard'
      }
    },
    submit: function () {
      const self = this
      const userData = {
        email: Utils.encodeEmail(this.email),
        password: this.password,
      }

      this.$isLoading(true)
      Api.login(userData, function (response) {
        if (response.success) {
          if (window.useChatV2) {
            response.data.chat.version_2 = true
          }
          self.chatLogin(userData, response.data, function () {
            Api.getUser(userData, function (responseUser) {
              if (responseUser.success) {
                if (
                  !axios.defaults.dev &&
                  self.jsonAdmin &&
                  self.jsonAdmin.id !== 'default' &&
                  self.jsonAdmin.pt_id !== responseUser.data.pt_id
                ) {
                  self.$isLoading(false)
                  self.failed = 'Email ou palavra-passe incorretos!'
                  return false
                }

                responseUser.data.password = userData.password

                if (Utils.isEmployee(responseUser.data.type)) {
                  Api.getPermissions(responseUser.data.db_id, function (permissionsResponse) {
                    if (permissionsResponse.success && permissionsResponse.data && permissionsResponse.data.length) {
                      responseUser.data.permissions = permissionsResponse.data
                      if (response.data && response.data.employees && response.data.employees.chat_log) {
                        const employeesViewPermission = responseUser.data.permissions.find(function (p) {
                          return p.name === 'employees_view'
                        })
                        if (employeesViewPermission && employeesViewPermission.value === '1') {
                          Api.getUserWithId(
                            {
                              id: responseUser.data.pt_id,
                              fields: ['chat_id'],
                            },
                            function (responseUserId) {
                              if (responseUserId.success) {
                                if (responseUserId.data[0]) {
                                  responseUser.data.chat_id = responseUserId.data[0].chat_id
                                }
                                self.finish(responseUser.data, response.data)
                              } else {
                                self.$isLoading(false)
                                self.$alert(responseUserId.message, '', 'warning', Utils.getAlertOptions())
                              }
                            },
                          )
                        } else {
                          self.finish(responseUser.data, response.data)
                        }
                      } else {
                        self.finish(responseUser.data, response.data)
                      }
                    } else {
                      self.$isLoading(false)
                      self.$alert(permissionsResponse.message, '', 'warning', Utils.getAlertOptions())
                    }
                  })
                } else {
                  self.finish(responseUser.data, response.data)
                }
              } else {
                self.$isLoading(false)
                self.failed = responseUser.message
              }
            })
          })
        } else {
          self.$isLoading(false)
          self.failed = response.message
        }
      })
    },
    chatLogin: function (user, config, callback) {
      const self = this
      if (config && config.chat && config.chat.version_2) {
        ChatApi.loginChatV2(
          {
            email: user.email,
            password: user.password,
          },
          function (response) {
            if (response.success) {
              window.chatLoginToken = response.data
              callback()
            } else {
              self.$isLoading(false)
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        callback()
      }
    },
    finish: function (data, configurations) {
      const self = this

      window.language = configurations && configurations.lng ? configurations.lng : 'pt'
      switch (window.language) {
        case 'en':
          window.datepickerLanguage = 'en-EN'
          break
        case 'es':
          window.datepickerLanguage = 'es-ES'
          break
        case 'fr':
          window.datepickerLanguage = 'fr-FR'
          break
        default:
          window.datepickerLanguage = 'pt-PT'
          break
      }

      this.getStrings(function () {
        data.configurations = configurations
        Utils.refreshUserFields(data)

        localStorage.removeItem('user_login')
        localStorage.setItem(
          'user_login',
          JSON.stringify({
            email: data.email,
            password: data.password,
          }),
        )

        Utils.setStorage('user', data)
        window.cachePhotos = {}

        Api.getEnums(function () {
          self.$isLoading(false)
          self.goFirstPage(data)
        })

        if (data.configurations.mqtt) {
          self.enableMqtt()
        }
      })
    },
    getStrings: function (callback) {
      const self = this
      Api.getStrings(function (response) {
        if (response.success) {
          window.strings = response.data
          Utils.setStorage('strings', window.strings)
          callback()
        } else {
          self.$isLoading(false)
          self.failed = response.message
        }
      })
    },
    goFirstPage: function (data) {
      if (window.urlToOpenAfterLogin) {
        window.open(window.urlToOpenAfterLogin, '_self')
        delete window.urlToOpenAfterLogin
        return true
      }
      if (data && data.configurations && data.configurations.show_terms && !Utils.isEmployee(data.type) && !data.accept_terms_date) {
        this.$router.navigate.replace({ path: '/terms' })
      } else {
        this.$router.navigate.replace({ path: '/home' })
      }
    },
    enableMqtt: function () {
      const self = this
      try {
        window.mqttDashboard = mqtt.connect('wss://mqtt.mkgest.com:8083')
        window.mqttDashboard.on('connect', () => {
          console.log('Dashboard MQTT client connected')
        })
        window.mqttDashboard.on('error', (err) => {
          console.log('Dashboard MQTT client error:', err)
        })
        window.mqttDashboard.on('close', () => {
          console.log('Dashboard MQTT client closed')
        })
        window.mqttDashboard.on('reconnect', () => {
          console.log('Dashboard MQTT client reconnected')
        })
        window.mqttDashboard.on('message', (topic, message) => {
          try {
            if (topic.indexOf('dashboard') === -1) {
              console.log('### DASHBOARD MQTT SOCKET IGNORED ###')
              return false
            }
            let jsonData = String.fromCharCode(...message)
            const isBase64 = jsonData.indexOf('{"id":') === -1 ? true : false
            if (isBase64) {
              jsonData = atob(jsonData)
            }
            const byteArray = new Uint8Array(jsonData.length)
            for (let i = 0; i < jsonData.length; i++) {
              byteArray[i] = jsonData.charCodeAt(i)
            }
            const decoder = new TextDecoder('utf-8')
            jsonData = decoder.decode(byteArray)
            jsonData = JSON.parse(jsonData)
            window.postMessage(
              JSON.stringify({
                event: 'dashboard-notification',
                data: jsonData,
              }),
              '*',
            )
            self.refreshLocalCache(jsonData)
          } catch (error) {
            console.log(error)
          }
        })
        self.activeRefreshLocalCache()
      } catch (error) {
        console.log(error)
      }
    },
    activeRefreshLocalCache: function () {
      if (window.useResetCacheSockets && window.mqttDashboard) {
        window.mqttDashboard.subscribe(
          axios.defaults.dev ? 'dev/dashboard/' + axios.defaults.ptId + '/cache' : 'dashboard/' + axios.defaults.ptId + '/cache',
        )
      }
    },
    refreshLocalCache: function (data) {
      if (window.useResetCacheSockets && data) {
        Utils.removeRequestCache(data.id)
      }
    },
  },
}
</script>
